const middleware = {}

middleware['finalized'] = require('../middleware/finalized.js')
middleware['finalized'] = middleware['finalized'].default || middleware['finalized']

middleware['legacy-auth'] = require('../middleware/legacy-auth.js')
middleware['legacy-auth'] = middleware['legacy-auth'].default || middleware['legacy-auth']

middleware['legacy-guest'] = require('../middleware/legacy-guest.js')
middleware['legacy-guest'] = middleware['legacy-guest'].default || middleware['legacy-guest']

middleware['sell-geo'] = require('../middleware/sell-geo.js')
middleware['sell-geo'] = middleware['sell-geo'].default || middleware['sell-geo']

middleware['tealium'] = require('../middleware/tealium.js')
middleware['tealium'] = middleware['tealium'].default || middleware['tealium']

export default middleware
